import Auid from 'Auid';
import { OnetrustCookieHelper } from 'Onetrust';
import initializeEvent from './initializeEvent';
import processForThirdParties from './processForThirdParties';

/**
 * Used when logging an internal event outside the context of a framework (e.g., inline-head-scripts.js)
 * @param {string} type
 * @param {object} payload
 * @param {object} [options]
 * @param {import('ServerTime').default | import('FrontRoyalConfig').ConfigFactory} [options.serverTimeOrConfigFactory]
 * @param {object[]} [options.bundledEvents]
 * @param {boolean} [options.log_to_customerio]
 */

export default function logTinyEvent(type, payload, options = {}) {
    const bundledEvents = options.bundledEvents || [];

    // We accept a serverTimeOrConfigFactory, but it should be fine to not pass this. In fact, if
    // ConfigFactory has already set serverClientTimeOffset on the window (see `set serverTime(val)`), then
    // it will make no difference to pass it or not, since the serverTimeOffset will be grabbed from the window.
    const event = initializeEvent(type, payload, { serverTimeOrConfigFactory: options.serverTimeOrConfigFactory });

    if (options.segmentio) processForThirdParties(event, options);

    if (window.RUNNING_IN_TEST_MODE) return undefined;

    return window.fetch(`${window.ENDPOINT_ROOT}/api/event_bundles.json`, {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
            auid: Auid.ensure(),
            ...(window.CF_ACCESS_CLIENT_ID && window.CF_ACCESS_CLIENT_SECRET
                ? {
                      'CF-Access-Client-Id': window.CF_ACCESS_CLIENT_ID,
                      'CF-Access-Client-Secret': window.CF_ACCESS_CLIENT_SECRET,
                  }
                : {}),
        },
        body: JSON.stringify({
            record: {
                events: [event, ...bundledEvents],
            },
            meta: {
                consent_disabled_integrations: OnetrustCookieHelper.getDisabledIntegrationsObject(),
            },
        }),
    });
}
