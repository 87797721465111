import { generateGuid } from 'guid';
import { frontRoyalHref } from 'FrontRoyalHref';
import ServerTime, { ensureServerClientTimeOffsetOnWindow } from 'ServerTime';
import { ensurePageLoadId } from './PageLoadId';

/**
 * @todo This will one day replace the Angular _getEvent method, but that will require figuring out some more
 *  complexities that aren't needed until we start batch logging in React .asdasd Also, the caller of this method
 *  will need to pass properties set by dependencies, like `in_offline_mode`.
 * @param {string} type
 * @param {object} payload
 * @param {object} [options]
 * @param {ServerTime} [options.serverTimeOrConfigFactory]
 * @param {boolean} [options.log_to_customerio]
 * @returns {object}
 */
export default function initializeEvent(type, payload, options = {}) {
    const serverTimeOrConfigFactory =
        options.serverTimeOrConfigFactory || new ServerTime(ensureServerClientTimeOffsetOnWindow());
    const clientTimestamp = Date.now();
    const date = new Date(clientTimestamp);

    let serverTimestamp;
    if (window.RUNNING_IN_TEST_MODE && !serverTimeOrConfigFactory?.getServerTimestamp) {
        serverTimestamp = 0;
    } else {
        serverTimestamp = serverTimeOrConfigFactory?.getServerTimestamp
            ? serverTimeOrConfigFactory.getServerTimestamp(date.getTime()) / 1000
            : null;
    }

    const extra = {
        log_to_customerio: options.log_to_customerio || false,
        event_type: type,
        server_timestamp: serverTimestamp,
        id: generateGuid(),
        cordova: !!window.CORDOVA,
        page_load_id: ensurePageLoadId(),
        url: frontRoyalHref(),
        buffered_time: 0,
        client_utc_timestamp: clientTimestamp / 1000,
        client_local_time: [
            date.getFullYear(),
            date.getMonth(),
            date.getDate(),
            date.getHours(),
            date.getMinutes(),
            date.getSeconds(),
            date.getMilliseconds(),
        ],
        client_utc_time: [
            date.getUTCFullYear(),
            date.getUTCMonth(),
            date.getUTCDate(),
            date.getUTCHours(),
            date.getUTCMinutes(),
            date.getUTCSeconds(),
            date.getUTCMilliseconds(),
        ],
        client_offset_from_utc: date.getTimezoneOffset() / 60,
    };

    return {
        ...extra,
        ...payload,
    };
}
