import ClientStorage from 'ClientStorage';
import initializeEvent from './initializeEvent';

export default function getUtmParamsEventIfPresent() {
    const url = new URL(window.location.href);
    const searchParams = url.searchParams;
    const utmMap = {};
    const eventProperties = {};

    searchParams.forEach((value, key) => {
        if (key.match(/utm_/) || key === 'ad_network') utmMap[key] = value;
    });

    if (Object.keys(utmMap).length > 0) ClientStorage.setClientDomainCookie('utmMap', JSON.stringify(utmMap));

    ['utm_source', 'utm_content', 'utm_campaign', 'utm_medium', 'utm_term', 'ad_network'].forEach(key => {
        if (utmMap[key]) eventProperties[key] = utmMap[key];
    });

    if (Object.keys(eventProperties).length > 0) return initializeEvent('user:set_utm_params', eventProperties);

    return undefined;
}
