// See full documentation in onetrust.md

import Cookies from 'js-cookie';

// This class acts as a bridge between OneTrust's "Privacy Preferences" cookie pop-up
// and the integrations we have enabled in Segment via event_logger.js.
//
// The idea is to retrieve the preferences that are saved via the OneTrust script to a global
// variable in JS and then convert them to a list of disabled integrations for use in the
// Segment JS API. EventLogger will check these preferences on every log call.
export default {
    // There are 4 groups of cookies that we've configured for users to control in OneTrust
    // Each group is identified by a string; specifically the strings '1', '2', '3', and '4'
    //
    // '1' - Strictly necessary cookies. Can't be disabled.
    // '2' - Performance cookies. These cookies allow us to measure and improve the performance of our site.
    // '3' - Functional cookies. These cookies enable the website to provide enhanced functionality and personalization.
    // '4' - Targeting cookies. These cookies may be set through our site by our advertising partners.
    //
    // For the above taxonomy, we list each of the Segment integrations below and their corresponding category to disable.
    // If we add a new integration without adding it to this list, we will leave it enabled by default for now.
    identifierToIntegration: {
        1: ['Google Tag Manager'], // we will put rules into Google Tag Manager itself for the integrations it provides
        2: ['Amplitude'],
        3: [],
        4: ['Facebook Pixel', 'Facebook Conversions API'],
    },

    integrationDisabled(integration) {
        return this.getDisabledIntegrationsObject()[integration] === false;
    },

    // Returns a map of integrations that are disabled for use with Segment library
    // e.g.: given group 4 being disabled, we'd expect a return value like:
    // { 'Facebook Pixel': false, 'Facebook Conversions API': false }
    getDisabledIntegrationsObject() {
        const groups = this._getOneTrustActiveGroups();
        const disabledGroups = [['1', '2', '3', '4'], groups].reduce((a, b) => a.filter(c => !b.includes(c)));
        const disabledIntegrationsMap = disabledGroups
            .map(group => this.identifierToIntegration[group])
            .flat()
            .filter(Boolean)
            .reduce((memo, integration) => {
                memo[integration] = false;
                return memo;
            }, {});

        return disabledIntegrationsMap;
    },

    _getOneTrustActiveGroups() {
        // Onetrust sets a consented groups value on window
        // Example value on first load:
        // ',C0001,'
        // Being in this list means enabled
        if (window.requiresOnetrust === false || window.disableCookieBanner === true) {
            // we know that we don't require consent, so just fall back to all groups enabled
            return ['1', '2', '3', '4'];
        }

        const jsGroups = window.OptanonActiveGroups?.split(',')
            .filter(Boolean)
            .map(onetrustJsGroupValue => Number.parseInt(onetrustJsGroupValue.replace('C', ''), 10).toString())
            .filter(mappedOnetrustJsGroupValue => mappedOnetrustJsGroupValue !== 'NaN');

        // Onetrust stores a consented groups value in a cookie that's in a query string format, so we can utilize URLSearchParams to parse it
        // Example value on first load:
        // 'C0001:1,C0003:0,C0002:0,C0004:0'
        // Having a ':1' prefix means enabled
        const parsedCookie = new URLSearchParams(Cookies.get('OptanonConsent'));
        const cookieGroups = parsedCookie
            .get('groups')
            ?.split(',')
            .map(onetrustCookieGroupValue => {
                // Check the '0' or '1' after the colon
                const keyval = onetrustCookieGroupValue.split(':');
                if (keyval[1] === '1') {
                    // Stripe the 'C' and the leading zeros
                    return Number.parseInt(keyval[0].replace('C', ''), 10).toString();
                }
                return undefined;
            })
            .filter(Boolean);

        const parsedGroups = jsGroups || cookieGroups;

        if (parsedGroups?.length) {
            // if a consented group value exists we'll use that value
            return parsedGroups;
        }
        // consent is required, we'll fallback for group 1
        return ['1'];
    },
};
