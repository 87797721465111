import BuildConfig from 'BuildConfig';
import * as userAgentHelper from 'userAgentHelper';

const BROWSER_AND_BUILD_INFO = {
    ...BuildConfig,
    ...userAgentHelper.getSnakeCasedObject(),

    // clientIdentifier can be `ios`, `android`, `web`, or `marketing`.
    // Before around 02/26/2020, events from the dynamic landing page
    // would have the `web` client, since we were using FrontRoyal instead
    // of events.js on that page.  We have since switched to setting the
    // clientIdentifier in the controller, so if it comes from the marketing_controller,
    // it will be labeled as `marketing`.
    client: window.clientIdentifier || 'web',
};

export default BROWSER_AND_BUILD_INFO;
