import Cookies from 'js-cookie';

export default function processForThirdParties(event, options = {}) {
    event = _processOptions(event, options);
    _maybeSendToThirdParties(event, options);
    return event;
}

// FIXME: Matt L thinks we could get rid of this intermediary mutating step by simply setting most of these directly in
// the payload at the logging call-site. Only a handful of events actually set these things, so it feels disjointed
// to have this centralized method.
function _processOptions(event, options = {}) {
    const mutatedEvent = { ...event };

    if (options.log_to_customerio) {
        mutatedEvent.log_to_customerio = true;
    }

    // Add certain properties to internal events which are special server conversion events for use in server destinations (e.g., CAPI).
    // Note: In Cordova we disable ad tracking, such as the Facebook Pixel, thus client Segment destinations won't be logging there.
    // But we need explicitly check here before setting the event flag to keep our server destinations from erroneously logging, especially
    // since it'd violate App Tracking in iOS.
    if (options.log_to_server_conversions_segment && !window.CORDOVA) {
        mutatedEvent.log_to_server_conversions_segment = true;

        // These are set by default in the Segment client library using Segment properties, but we need to set them in the payload to add
        // to the Segment properties in server calls
        // See https://segment.com/docs/connections/destinations/catalog/facebook-pixel-server-side/#default-mappings-to-facebook-properties
        // See https://segment.com/docs/connections/destinations/catalog/facebook-pixel-server-side/#implementing-server-event-parameter-requirements
        mutatedEvent.action_source = 'website';
        mutatedEvent.event_source_url = options.context?.page?.url ?? window.location.href;
        mutatedEvent.user_agent = window.navigator.userAgent;

        // These are set by the Pixel and sent to server CAPI
        mutatedEvent.fbp = Cookies.get('_fbp');
        mutatedEvent.fbc = Cookies.get('_fbc') || Cookies.get('scraped_fbc');

        // This is set by the Insight Tag and sent to server CAPI
        mutatedEvent.li_fat_id = Cookies.get('li_fat_id') || Cookies.get('scraped_li_fat_id');
    }

    return mutatedEvent;
}

function _maybeSendToThirdParties(event, options = {}) {
    options = { segmentio: true, ...options };

    if (options.segmentio) {
        // put in a timeout in case anything gets added to the event after the log call
        // FIXME: We should try to gut this setTimeout. We think addDurationInfo is the only reason we have to do it.
        window.setTimeout(() => {
            const eventType = options.segmentioType || event.event_type;

            // FIXME: Think about if we'd want to remove all of our internal times to make the events cleaner
            // in Segment and destinations. This used to be the case with Iguana events because of the event being
            // logged before `_addTimes` was called in `asJson`.
            const segmentProperties = { ...event };

            if (options.segmentioLabel) segmentProperties.label = options.segmentioLabel;

            // customize enabled/disabled segmentio integrations
            const integrations = {
                'Facebook Pixel': !!options.log_to_server_conversions_segment, // only send events we're also sending with server CAPI
            };

            if (options.disableAmplitude) integrations.Amplitude = false;

            window.analytics?.track(eventType, segmentProperties, { integrations, context: options.context });
        }, 0);
    }
}
