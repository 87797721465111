import ClientStorage from 'ClientStorage';

export default {
    GOOD_LEAD_ANSWERS: {
        survey_highest_level_completed_education_description: ['undergraduate_bachelor', 'masters', 'doctorate'],
        survey_years_full_time_experience: ['2_6', '7_10', '11_15', '16_plus'],
        survey_most_recent_role_description: ['senior_management_role', 'management_role', 'professional_role'],
    },

    MADRIVO_QUALIFIED_ANSWERS: {
        survey_highest_level_completed_education_description: ['undergraduate_bachelor', 'masters', 'doctorate'],
        survey_years_full_time_experience: ['7_10', '11_15', '16_plus'],
        survey_most_recent_role_description: [
            'senior_management_role',
            'management_role',
            'professional_role',
            'administrative_role',
        ],
    },

    allServer() {
        const preSignupAnswers = ClientStorage.getCookie('preSignupAnswers');
        const utmMap = ClientStorage.getCookie('utmMap');
        const allServer = { ...preSignupAnswers };
        if (utmMap?.utm_source) allServer.utm_source = utmMap.utm_source;
        return allServer;
    },

    storePreSignupAnswers(newAnswers) {
        const existingAnswers = ClientStorage.getCookie('preSignupAnswers');
        const updatedAnswers = { ...existingAnswers, ...newAnswers };
        ClientStorage.setClientDomainCookie('preSignupAnswers', JSON.stringify(updatedAnswers));
    },

    clear() {
        ClientStorage.removeDomainCookie('preSignupAnswers');
        ClientStorage.removeDomainCookie('utmMap');
        ClientStorage.removeDomainCookie('marketingSignupUrl');
    },

    // See https://trello.com/c/sqglpXD2.
    isGoodLead() {
        return this._checkLead(this.GOOD_LEAD_ANSWERS);
    },

    // See https://trello.com/c/Jdpbyk4u
    isMadrivoQualified() {
        return this._checkLead(this.MADRIVO_QUALIFIED_ANSWERS);
    },

    _checkLead(criteria) {
        const preSignupAnswers = ClientStorage.getCookie('preSignupAnswers');

        if (!preSignupAnswers) return false;

        return Object.entries(criteria).every(([key, values]) => {
            const answer = preSignupAnswers[key];
            return answer && values.includes(answer);
        });
    },
};
