import { generateGuid } from 'guid';

export function ensurePageLoadId() {
    if (!window.pageLoadId) {
        window.pageLoadId = generateGuid();
    }
    return window.pageLoadId;
}

export function unsetPageLoadId() {
    delete window.pageLoadId;
}
