import ClientStorage from 'ClientStorage';
import { generateGuid } from 'guid';

const Auid = {
    get(currentUser) {
        let storedAuid = currentUser?.id || ClientStorage.getItem('auid');

        // we've seen this somehow set to 'undefined' in ClientStorage (perhaps due to some legacy code),
        // so we're insulating from error if we've detected this situation.
        if (storedAuid === 'undefined' || storedAuid === 'null') {
            storedAuid = undefined;
            this.remove();
        }
        return storedAuid;
    },

    set(auid) {
        if (!auid) {
            this.remove();
            return;
        }

        ClientStorage.setItem('auid', auid);
    },

    ensure(currentUser) {
        if (!this.get(currentUser)) {
            const auid = generateGuid();
            this.set(auid);
        }

        return this.get();
    },

    reset() {
        this.remove();
        return this.ensure();
    },

    remove() {
        ClientStorage.removeItem('auid');
    },
};

export default Auid;
