import Cookies from 'js-cookie';
import storageAvailable from './storageAvailable';

const ClientStorage = {
    getItem(key) {
        // We fall back to checking cookies if localStorage isn't available or if we're receiving
        // a domain cookie set on a different subdomain.
        const value = (this.localStorageEnabled && window.localStorage.getItem(key)) || Cookies.get(key);

        // Proactively backfill items that become a domain cookie by cleaning up their legacy storage and
        // setting a domain cookie if one doesn't exist. We're proactive about this because we want to
        // avoid the possibility of having both a non-domain and domain cookie set, since there's no way
        // to specifically get one or the other.
        if (value && this._shouldUseDomainCookie(key)) {
            this._legacyCleanup(key);

            if (!Cookies.get(key)) {
                this.setItem(key, value);
            }
        }

        return value;
    },
    setItem(key, value) {
        if (this.localStorageEnabled && !this._shouldUseDomainCookie(key)) {
            window.localStorage.setItem(key, value);
        } else {
            // Note: We detect if we're trying to set the key to the same value it's already set to
            // because we'll be issuing a network request to set the domain cookie. This particularly
            // came up with some items we were setting in multiple places, which could trigger multiple
            // network requests.
            //
            // There are a few caveats:
            //  1) We need to do the _legacyCleanup first to prevent legacy items from falsely triggering the return
            //  2) When cookieDomain is null, which will be the case for localhost, we'll simply be setting a non-domain
            //      cookie. But non-domain cookies get cleaned up in _legacyCleanup. That means our same-value logic won't
            //      trigger, which isn't a big deal, but because of that we check if there's a cookieDomain before tring to
            //      issue a network request in _setDomainCookie.

            this._legacyCleanup(key);

            if (Cookies.get(key) === String(value)) {
                return;
            }

            // Synchronously set a domain cookie using the client
            this.setClientDomainCookie(key, String(value));

            // Try to use the server to set a new version of the domain cookie, because of the
            // seven-day max expiration policy that some browsers (notably Safari) put on client-set
            // cookies. The server response will contain a Set-Cookie header that will overwrite
            // the client-set one.
            if (this._getCookieDomain()) {
                this._setDomainCookie(key, value);
            }
        }
    },
    removeItem(key) {
        if (this.localStorageEnabled && !this._shouldUseDomainCookie(key)) {
            window.localStorage.removeItem(key);
        } else {
            this._legacyCleanup(key);
            Cookies.remove(key, { path: '/', domain: this._getCookieDomain() });
        }
    },

    // Note: This does not work when falling back to cookies. It is currently only
    // used in session_tracker.js, and would only be a problem if we are falling back
    // to cookies instead of localStorage
    keys() {
        return Object.keys(window.localStorage || {});
    },

    // Note: This is only used for the testing shim
    clear() {
        window.localStorage?.clear();
    },

    setClientDomainCookie(key, value, overrides = {}) {
        Cookies.set(key, String(value), {
            path: '/',
            domain: this._getCookieDomain(),

            // Some browsers (notably Safari) have a seven-day max expiration policy for
            // cookies set on the client
            // See https://webkit.org/blog/8613/intelligent-tracking-prevention-2-1/
            // See https://www.cookiestatus.com/
            expires: 3650, // 365 days * 10 years

            ...overrides,
        });
    },

    removeDomainCookie(key) {
        this._legacyCleanup(key);
        Cookies.remove(key, { path: '/', domain: this._getCookieDomain() });
    },

    getCookie(key) {
        let value = Cookies.get(key);
        try {
            value = JSON.parse(value);
        } catch (e) {
            // Do nothing
        }
        return value;
    },

    localStorageEnabled: storageAvailable('localStorage'),

    forceOnetrustReconsentIfLegacy() {
        // The cookie consent value is in a query string format, so we can utilize URLSearchParams to parse it
        const parsedCookie = new URLSearchParams(Cookies.get('OptanonConsent'));
        const version = Number.parseInt(parsedCookie.get('version')?.replaceAll('.', ''), 10) || 0;
        if (version > 0 && version < 6340) {
            Cookies.remove('OptanonAlertBoxClosed', { path: '/', domain: this._getCookieDomain() });
        }
    },

    cleanUpLegacyLocalStorageAuthHeadersInWeb() {
        if (this.getItem('auth_headers')) {
            this.removeItem('auth_headers');
        }
    },

    async _setDomainCookie(key, value, retries = 2) {
        const response = await window.fetch(`${window.ENDPOINT_ROOT}/api/status/set_domain_cookie.json`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                ...(window.CF_ACCESS_CLIENT_ID && window.CF_ACCESS_CLIENT_SECRET
                    ? {
                          'CF-Access-Client-Id': window.CF_ACCESS_CLIENT_ID,
                          'CF-Access-Client-Secret': window.CF_ACCESS_CLIENT_SECRET,
                      }
                    : {}),
            },
            body: JSON.stringify({ name: key, value }),
        });

        if (!response.ok && retries > 0) {
            await this._setDomainCookie(key, value, retries - 1);
        }
    },

    _getCookieDomain() {
        // Not applicable in localhost or a custom aws staging server
        // See also PedagoDomains#cookie_domain
        let cookieDomain;
        if (window.location.hostname.includes('quantic.edu')) {
            cookieDomain = 'quantic.edu';
        } else if (window.location.hostname.includes('quantic.cn')) {
            cookieDomain = 'quantic.cn';
        } else if (window.location.hostname.includes('smart.ly')) {
            cookieDomain = 'smart.ly';
        } else if (window.location.hostname.includes('staging-quantic.com')) {
            cookieDomain = 'staging-quantic.com';
        } else if (window.location.hostname.includes('staging-smartly.com')) {
            cookieDomain = 'staging-smartly.com';
        } else if (window.location.hostname.includes('ngrok')) {
            cookieDomain = `${window.preloadedConfig?.ngrok_subdomain}.ngrok.io`;
        }

        return cookieDomain;
    },

    _shouldUseDomainCookie(key) {
        // oauth_redirect_target: Used to redirect a user back to the DLP if they registered from there
        // utm_source: Scraped from a DLP ad link
        //
        // FIXME: Using this list to detect when a domain cookie is required is disjointed and confusing
        // to the caller of setItem. We should instead make a separate setDomainCookieItem method.
        return (
            [
                'auid',
                'continueApplicationInMarketing',
                'oauth_redirect_target',
                'utm_source', // LEGACY (moved to utmMap)
                'prefilledEmail',

                // LEGACY (moved to preSignupAnswers)
                'survey_highest_level_completed_education_description',
                'survey_years_full_time_experience',
                'survey_most_recent_role_description',
            ].includes(key) && !(window.CORDOVA || window.RUNNING_IN_TEST_MODE)
        );
    },

    _legacyCleanup(key) {
        // Clean up a lingering localStorage value that could have been set before shifting an item to a domain cookie
        if (this.localStorageEnabled) {
            window.localStorage.removeItem(key);
        }

        // Clean up a lingering non-domain cookie if it exists to avoid creating duplicate cookies,
        // which can happen because the browser views separately a non-domain and domain cookie of the
        // same name, but the client can't specifically ask for either one (js-cookie would probably just
        // return the first one in the document.cookies string).
        // We should only have been setting non-domain cookies before the work in https://trello.com/c/Urs5MYOl
        Cookies.remove(key, { path: '/' });
    },

    // This method is useful for purging all entries that match a regular expression
    // from localStorage.
    purgeEntriesFromLocalStorage(regex) {
        if (!this.localStorageEnabled) return;

        Object.keys(window.localStorage).forEach(key => {
            if (key.match(regex)) window.localStorage.removeItem(key);
        });
    },
};

export default ClientStorage;
