import ClientStorage from 'ClientStorage';
import { OnetrustCookieHelper } from 'Onetrust';
import { SMARTLY_DOMAIN, QUANTIC_DOMAIN, SMARTLY_DOMAIN_STAGING, QUANTIC_DOMAIN_STAGING } from 'PedagoDomainConstants';

const SegmentioHelper = {
    /* eslint-disable */
    // Taken from https://github.com/segmentio/snippet/blob/4.13.2/template/snippet.js
    // Custom changelog:
    //  - Replaced <%%> variables with actual values
    //  - Commented out analytics._writeKey so we don't have to pass our key into snippet()
    //      (Not sure what that was for, it was new as of snippet v4.13.2 and didn't seem to affect anything)
    //  - Commented out the initial analytics.load and analytics.page since we want to control when we do those
    //  - Added _customConfig() to house our custom setup, like Onetrust middleware
    snippet() {
        // Create a queue, but don't obliterate an existing one!
        var analytics = (window.analytics = window.analytics || []);

        // If the real analytics.js is already on the page return.
        if (analytics.initialize) return;

        // If the snippet was invoked already show an error.
        if (analytics.invoked) {
            if (window.console && console.error) {
                console.error('Segment snippet included twice.');
            }
            return;
        }

        // Invoked flag, to make sure the snippet
        // is never invoked twice.
        analytics.invoked = true;

        // A list of the methods in Analytics.js to stub.
        analytics.methods = [
            'trackSubmit',
            'trackClick',
            'trackLink',
            'trackForm',
            'pageview',
            'identify',
            'reset',
            'group',
            'track',
            'ready',
            'alias',
            'debug',
            'page',
            'once',
            'off',
            'on',
            'addSourceMiddleware',
            'addIntegrationMiddleware',
            'setAnonymousId',
            'addDestinationMiddleware',
        ];

        // Define a factory to create stubs. These are placeholders
        // for methods in Analytics.js so that you never have to wait
        // for it to load to actually record data. The `method` is
        // stored as the first argument, so we can replay the data.
        analytics.factory = function (method) {
            return function () {
                var args = Array.prototype.slice.call(arguments);
                args.unshift(method);
                analytics.push(args);
                return analytics;
            };
        };

        // For each of our methods, generate a queueing stub.
        for (var i = 0; i < analytics.methods.length; i++) {
            var key = analytics.methods[i];
            analytics[key] = analytics.factory(key);
        }

        // Define a method to load Analytics.js from our CDN,
        // and that will be sure to only ever load it once.
        analytics.load = function (key, options) {
            // Create an async script element based on your key.
            var script = document.createElement('script');
            script.type = 'text/javascript';
            script.async = true;
            script.src = 'https://cdn.segment.com/analytics.js/v1/' + key + '/analytics.min.js';

            // Insert our script next to the first script element.
            var first = document.getElementsByTagName('script')[0];
            first.parentNode.insertBefore(script, first);
            analytics._loadOptions = options;
        };
        // analytics._writeKey = apiKey;

        // Add a version to keep track of what's in the wild.
        analytics.SNIPPET_VERSION = '4.13.2';

        // Load Analytics.js with your key, which will automatically
        // load the tools you've enabled for your account. Boosh!
        // analytics.load(apiKey);

        // Make the first page call to load the integrations. If
        // you'd like to manually name or tag the page, edit or
        // move this call however you'd like.
        // analytics.page();

        this._customConfig();
    },

    _customConfig() {
        const utmMap = ClientStorage.getCookie('utmMap');

        // DELETEME: after the GA4 migration is complete, there will be no window.ga
        analytics.ready(() => {
            if (!window.ga) return;

            // enable cross-domain tracking. See
            // * https://segment.com/docs/connections/destinations/catalog/google-analytics/#cross-domain-tracking
            // * https://developers.google.com/analytics/devguides/collection/gtagjs/cross-domain#using_a_single_snippet_on_all_domains
            window.ga('require', 'linker');
            window.ga('linker:autoLink', [
                SMARTLY_DOMAIN,
                QUANTIC_DOMAIN,
                SMARTLY_DOMAIN_STAGING,
                QUANTIC_DOMAIN_STAGING,
            ]);

            // See https://developers.google.com/analytics/devguides/collection/analyticsjs/field-reference#campaignName
            if (utmMap) {
                if (utmMap.utm_campaign) window.ga('set', 'campaignName', utmMap.utm_campaign);
                if (utmMap.utm_source) window.ga('set', 'campaignSource', utmMap.utm_source);
                if (utmMap.utm_medium) window.ga('set', 'campaignMedium', utmMap.utm_medium);
                if (utmMap.utm_term) window.ga('set', 'campaignKeyword', utmMap.utm_term);
                if (utmMap.utm_content) window.ga('set', 'campaignContent', utmMap.utm_content);
            }
        });

        // Configure a Segment middleware that will blacklist any integrations our Onetrust helper says are disabled,
        // as well as a few other various scenarios.
        // By default integrations will fire unless explicitly blacklisted by sending `{ integrationName: false }` in the
        // payload.obj.integrations object. You can view the received integrations object in the Segment Debugger under the Raw tab.
        // See https://segment.com/docs/connections/sources/catalog/libraries/website/javascript/middleware/#using-source-middlewares
        analytics.addSourceMiddleware(({ payload, next, integrations }) => {
            const disabledIntegrations = {
                ...OnetrustCookieHelper.getDisabledIntegrationsObject(),

                // Server-only integrations
                'Facebook Conversions API': false,
                'LinkedIn Conversions API': false,
            };

            // In Cordova we have no consent banner, and we've opted-out of showing the iOS App Tracking modal, so
            // we need to just blanketly turn off bucket 4. Additionally, Google Analytics is turned off by virtue of
            // the Segment Mobile Tracking ID setting being blank, but let's turn it off in code as well to be safe.
            if (window.CORDOVA) {
                OnetrustCookieHelper.identifierToIntegration['4'].forEach(integration => {
                    disabledIntegrations[integration] = false;
                });

                disabledIntegrations['Google Analytics'] = false;
            }

            // We're nearing our limit on unique users in Amplitude and we don't feel it's worth it to increase our plan,
            // so we're turning Amplitude off when we're installing Segment in the Gatsby environment.
            if (window.GATSBY) {
                disabledIntegrations['Amplitude'] = false;
            }

            Object.assign(payload.obj.integrations, disabledIntegrations);
            next(payload);
        });
    },
    /* eslint-enable */
};

export default SegmentioHelper;
