const config = {
    build_number: '597225',
    build_timestamp: '2024-11-13T20:04:27.264Z',
    build_commit: '45f2c99dde1df7a2804c9f33682541c69a1a74db',
};

// locally, the build number is always 4122 (which allows us to load non-English content locally)
if (Number.isNaN(parseInt(config.build_number, 10))) {
    config.build_number = '4122';
}

export default config;
